<template>
    <gd-modal name="affiliate-detail-modal" :title="$t('frontend_groovesell_sub_aff_details')" width="1080px"
        bg-type="regular">
        <div v-if="selectedAffiliate" style="min-height: 60vh">
            <gd-tabs :tabs="affiliateDetailTabs" :current-tab="affiliateDetailsTab"
                :wrapper-class="'pt-4 px-4 gs-default-tabs'" :tab-class="'pb-2 mr-4 gs-default-tabs-item'"
                :tab-active-class="'border-primary border-b-2 text-primary gs-default-tabs-item-active'"
                :line-class="'gs-default-tabs-active-line'" @onClick="handleAffiliatesDetailTabChange" />
            <div v-if="affiliateDetailsTab === 'info'">
                <div class="flex flex-row">
                    <div class="flex flex-col w-3/4 px-4">
                        <div class="mt-2">
                            <div class="w-full flex flex-wrap">
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_index_name')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{ selectedAffiliate.rendered_name }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_index_email')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{ selectedAffiliate.rendered_email }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_paypal_email')
                                    }}</label>
                                    <label v-if="selectedAffiliate.affiliate_paypal" class="text-sm text-grey-700 mb-1">
                                        {{
                                            selectedAffiliate.rendered_affiliate_paypal
                                        }}</label>
                                    <label v-else class="text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_index_NA')
                                    }}</label>
                                </div>

                                <div class="w-full flex flex-row">
                                    <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                            $t('frontend_affiliate_index_username')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-1">
                                            {{ selectedAffiliate.rendered_username }}
                                        </label>
                                    </div>
                                    <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                            $t('frontend_affiliate_index_impresssion')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-1">
                                            {{ selectedAffiliate.impressions }}
                                        </label>
                                    </div>
                                    <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                            $t('frontend_affiliate_index_uniques') }}</label>
                                        <label class="text-sm text-grey-700 mb-1">
                                            {{ selectedAffiliate.uniques }}
                                        </label>
                                    </div>
                                </div>

                                <div class="w-full flex flex-row">
                                    <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                            $t('frontend_affiliate_index_sales') +" / "+ $t('frontend_affiliate_index_refunds')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-1">
                                            {{ selectedAffiliate.total_sales }} / {{ selectedAffiliate.total_refunds }}
                                        </label>
                                    </div>
                                    <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                            $t('frontend_affiliate_index_earned_commisions')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-1">
                                            {{
                                                selectedAffiliate.human_readable_total_commissions
                                            }}
                                        </label>
                                    </div>
                                    <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                        <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                            $t('frontend_affiliate_index_pending_commisions')
                                        }}</label>
                                        <label class="text-sm text-grey-700 mb-1">
                                            {{
                                                selectedAffiliate.human_readable_pending_commission
                                            }}
                                        </label>
                                    </div>
                                </div>


                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_index_due_commisions')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{
                                            selectedAffiliate.human_readable_processed_without_advances
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_index_commisions_on_hold')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{
                                            selectedAffiliate.human_readable_on_hold_commission
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t(
                                            'frontend_affiliate_index_being_processed_commisions'
                                        )
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{
                                            selectedAffiliate.human_readable_in_process_commission
                                        }}
                                    </label>
                                </div>

                                <div class="w-full mt-2 font-bold border-b border-primary text-primary">
                                    Payments
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('frontend_affiliate_index_advance_clawbacks')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{ selectedAffiliate.human_readable_commission_advance }} / {{ selectedAffiliate.human_readable_clawbacks }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-1">{{
                                        $t('Paid Commissions')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1">
                                        {{ selectedAffiliate.human_readable_alltime_paid_commission }}
                                    </label>
                                </div>


                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col border border-primary bg-grey-50 px-4 -mt-4 -ml-3 rounded">
                                    <label class="font-semibold text-sm text-primary">{{
                                        $t('Total')
                                    }}</label>
                                    <label class="font-semibold text-xs text-grey-700 mb-1">{{
                                        $t('Advances + Clawbacks + Commissions')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-1 font-bold">
                                        {{
                                            selectedAffiliate.human_readable_total_paid
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col w-1/4 border-l border-grey-300 p-4">
                        <div class="my-auto flex flex-col">
                            <gd-button size="auto" variant="primary" class="mb-4" @click="openMarkAsPaidModal">{{
                                $t('frontend_affiliate_index_process_payment')
                            }}</gd-button>

                            <gd-button size="auto" variant="primary" class="mb-4" @click="openClawbackModal">{{
                                $t('frontend_affiliate_index_add_clawback')
                            }}</gd-button>
                            <gd-button size="auto" variant="primary" class="mb-4" @click="openCommissionAdvanceModal">{{
                                $t('frontend_affiliate_index_add_commision_advance')
                            }}</gd-button>
                            <gd-button size="auto" variant="primary" class="mb-4" @click="openCommissionBonusModal">{{
                                $t('frontend_affiliate_index_tax_ledger_adjustment')
                            }}</gd-button>
                            <div class="-mt-2 text-center leading-none">
                                <span class="text-xs text-grey-500 italic">{{
                                    $t('frontend_affiliate_index_this_is_for_tax')
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'lifetime'">
                <div class="flex flex-row">
                    <div class="flex flex-col w-3/4 px-4">
                        <div class="mt-2">
                            <div class="w-full flex flex-wrap">
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_all_time_earned_commision')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.human_readable_alltime_earned_commission
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_all_time_paid_commision')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{ selectedAffiliate.human_readable_alltime_paid_commission }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_all_time_cash_advances')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.human_readable_commission_advance
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_all_time_claw_backs')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{ selectedAffiliate.human_readable_clawbacks }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'transactions'">
                <div v-if="selectedAffiliate.transactions && selectedAffiliate.transactions.length > 0">
                    <div id="affiliate-transactions-list-view" class="flex flex-row">
                        <v-client-table :data="selectedAffiliate.transactions" :columns="affiliatesTransactionsColumns"
                            :options="affiliatesTransactionsTableOptions" />
                    </div>
                    <div class="mb-4 pr-4 flex justify-end text-blue">
                        <router-link :to="{
                            name: 'transactions',
                            query: {
                                affiliate_id: selectedAffiliate.id,
                                date_range: 'all-time',
                                transaction_type: 'all',
                            },
                        }" target="_blank">{{ $t('frontend_affiliate_index_see_full_transaction') }}</router-link>
                    </div>
                </div>
                <div v-else class="p-4">
                    {{ $t('frontend_affiliate_index_no_transactions_recorded') }}
                </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'refunds'">
                <div v-if="selectedAffiliate.refunds && selectedAffiliate.refunds.length > 0
                    ">
                    <div id="affiliate-refunds-list-view" class="flex flex-row">
                        <v-client-table :data="selectedAffiliate.refunds" :columns="affiliatesRefundsColumns"
                            :options="affiliatesRefundsTableOptions" />
                    </div>
                    <div class="mb-4 pr-4 flex justify-end text-blue">
                        <router-link :to="{
                            name: 'transactions',
                            query: {
                                affiliate_id: selectedAffiliate.id,
                                date_range: 'all-time',
                                transaction_type: 'refund',
                            },
                        }" target="_blank">{{ $t('frontend_affiliate_index_see_full') }}</router-link>
                    </div>
                </div>
                <div v-else class="p-4">
                    {{ $t('frontend_affiliate_index_no_refunds') }}
                </div>
            </div>

            <div v-else-if="affiliateDetailsTab === 'links'">
                <div v-if="selectedAffiliate.registered_funnels && selectedAffiliate.registered_funnels.length > 0">
                    <div id="affiliate-links-list-view" class="flex flex-row">
                        <v-client-table :data="selectedAffiliate.registered_funnels" :columns="affiliatesLinksColumns"
                            :options="affiliatesLinksTableOptions">
                            <div slot="links" slot-scope="props">
                                <gd-button variant="outline" size="icon" title="Links" class="mr-2"
                                    @click="fetchAffiliateLinks(props.row.id)">
                                    <link-icon class="w-5 h-5 fill-current" />
                                </gd-button>
                            </div>
                        </v-client-table>
                    </div>
                </div>
                <div v-else class="p-4">
                    {{ $t('frontend_affiliate_index_no_funnels_found') }}
                </div>
            </div>

            <div v-else-if="affiliateDetailsTab === 'history'">
                <div v-if="selectedAffiliate.payment_bonus_history && selectedAffiliate.payment_bonus_history.length > 0"
                    id="affiliate-payments-list-view" class="flex flex-row">
                    <v-client-table :data="selectedAffiliate.payment_bonus_history" :columns="affiliatesPaymentsColumns"
                        :options="affiliatesPaymentsTableOptions">
                        <div slot="note" slot-scope="props" class="inline-block">
                            <div v-if="props.row.note && props.row.note != ''" v-tooltip="props.row.note">
                                <comment-icon class="w-4 h-4 fill-current" />
                            </div>
                        </div>
                    </v-client-table>
                </div>
                <div v-else class="p-4">
                    {{ $t('frontend_affiliate_index_no_payments_done') }}
                </div>
            </div>
            <div v-else-if="affiliateDetailsTab === 'tax_info'">
                <div class="flex flex-row">
                    <div class="flex flex-col w-3/4 px-4">
                        <div class="mt-2">
                            <div class="w-full flex flex-wrap">
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_name')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_name &&
                                            selectedAffiliate.affiliate_tax_info.legal_name != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_name : 'NA'
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_address')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_address &&
                                            selectedAffiliate.affiliate_tax_info.legal_address != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_address : 'NA'
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_address2')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_address_2 &&
                                            selectedAffiliate.affiliate_tax_info.legal_address_2 != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_address_2 : 'NA'
                                        }}
                                    </label>
                                </div>

                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_city')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_city &&
                                            selectedAffiliate.affiliate_tax_info.legal_city != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_city : 'NA'
                                        }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_state')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_state &&
                                            selectedAffiliate.affiliate_tax_info.legal_state != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_state : 'NA'
                                        }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_country')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_country &&
                                            selectedAffiliate.affiliate_tax_info.legal_country != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_country : 'NA'
                                        }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_zip_code')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.legal_zip_code &&
                                            selectedAffiliate.affiliate_tax_info.legal_zip_code != null
                                            ? selectedAffiliate.affiliate_tax_info.legal_zip_code : 'NA'
                                        }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_tax_id')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.tax_id &&
                                            selectedAffiliate.affiliate_tax_info.tax_id !=
                                            null
                                            ? selectedAffiliate.affiliate_tax_info.tax_id : 'NA'
                                        }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_resident_type')
                                    }}</label>
                                    <label class="text-sm text-grey-700 mb-2">
                                        {{
                                            selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null
                                            &&
                                            selectedAffiliate.affiliate_tax_info.resident_type &&
                                            selectedAffiliate.affiliate_tax_info.resident_type != 0
                                            ? getResidentType(selectedAffiliate.affiliate_tax_info.resident_type)
                                            : 'NA'
                                        }}
                                    </label>
                                </div>
                                <div class="w-1/3 pt-2 pb-2 pr-2 flex flex-col">
                                    <label class="font-semibold text-sm text-grey-700 mb-2">{{
                                        $t('frontend_affiliate_index_tax_legal_tax_form')
                                    }}</label>

                                    <div v-if="selectedAffiliate.affiliate_tax_info && selectedAffiliate.affiliate_tax_info != null &&
                                            selectedAffiliate.affiliate_tax_info.tax_form && selectedAffiliate.affiliate_tax_info.tax_form != null"
                                        class="flex flex-row">
                                        <gd-button size="auto" variant="primary" class="mr-2"
                                            @click="previewTaxForm(selectedAffiliate.affiliate_tax_info.tax_form)">{{
                                                $t('frontend_preview_file')
                                            }}</gd-button>
                                        <gd-button size="auto" variant="primary" class="mr-2"
                                            @click="downloadTaxForm(selectedAffiliate.affiliate_tax_info.tax_form)">{{
                                                $t('frontend_download_file')
                                            }}</gd-button>

                                    </div>
                                    <div v-else>
                                        <label class="text-sm text-grey-700 mb-2">NA</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </gd-modal>
</template>
<script>
import LinkIcon from '@/assets/images/icons/LinkIcon.svg'
import CommentIcon from '@/assets/images/icons/CommentIcon.svg'

export default {
    components: {
        LinkIcon,
        CommentIcon,
    },
    props: {
        selectedAffiliate: {
            type: Object,
            default: null,
        },
        openMarkAsPaidModal: {
            type: Function,
            default: () => { },
        },
        openClawbackModal: {
            type: Function,
            default: () => { },
        },
        openCommissionAdvanceModal: {
            type: Function,
            default: () => { },
        },
        openCommissionBonusModal: {
            type: Function,
            default: () => { },
        },
        fetchAffiliateLinks: {
            type: Function,
            default: () => { },
        },
        previewTaxForm: {
            type: Function,
            default: () => { },
        },
        downloadTaxForm: {
            type: Function,
            default: () => { },
        },


    },
    data() {
        return {
            affiliateDetailTabs: [
                { title: this.$t('frontend_affiliates'), value: 'info' },
                { title: this.$t('frontend_vendors_lifetime_commisions'), value: 'lifetime' },
                { title: this.$t('frontend_vendors_transactions'), value: 'transactions' },
                { title: this.$t('frontend_vendors_refund'), value: 'refunds' },
                { title: this.$t('frontend_vendors_links'), value: 'links' },
                { title: this.$t('frontend_vendors_payment_history'), value: 'history' },
                { title: this.$t('frontend_vendors_payment_tax_info'), value: 'tax_info' }
            ],
            affiliateDetailsTab: 'info',
            affiliatesTransactionsColumns: [
                'buyer_fullname',
                'buyer_email',
                'buyer_ip',
                'transaction_type',
                'commission_status',
                'formatted_date_time',
            ],
            affiliatesTransactionsTableOptions: {
                headings: {
                    formatted_date_time: this.$t('frontend_affiliate_index_formatted_date_time'),
                    buyer_fullname: this.$t('frontend_affiliate_index_buyer_fullname'),
                    buyer_email: this.$t('frontend_affiliate_index_buyer_email'),
                    buyer_ip: this.$t('frontend_affiliate_index_buyer_ip'),
                    transaction_type: this.$t('frontend_affiliate_index_transaction_type'),
                    commission_status: this.$t('frontend_affiliate_index_commision_status'),
                },
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
            affiliatesRefundsColumns: [
                'buyer_fullname',
                'buyer_email',
                'buyer_ip',
                'transaction_type',
                'commission_status',
                'formatted_date_time',
            ],
            affiliatesRefundsTableOptions: {
                headings: {
                    formatted_date_time: this.$t('frontend_affiliate_index_formatted_date_time'),
                    buyer_fullname: this.$t('frontend_affiliate_index_buyer_fullname'),
                    buyer_email: this.$t('frontend_affiliate_index_buyer_email'),
                    buyer_ip: this.$t('frontend_affiliate_index_buyer_ip'),
                    transaction_type: this.$t('frontend_affiliate_index_transaction_type'),
                    commission_status: this.$t('frontend_affiliate_index_commision_status'),
                },
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
            affiliatesLinksColumns: ['name', 'links'],
            affiliatesLinksTableOptions: {
                headings: {},
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
            affiliatesPaymentsColumns: [
                'readable_type',
                'human_readable_amount',
                'formatted_date_time',
                'note',
            ],
            affiliatesPaymentsTableOptions: {
                headings: {
                    formatted_date_time: 'Date Time',
                    readable_type: 'Type',
                    human_readable_amount: 'Amount',
                },
                perPage: 5,
                texts: {
                    filterPlaceholder: 'Search',
                    filter: '',
                },
            },
        }
    },
    methods: {
        handleAffiliatesDetailTabChange(newTab) {
            this.affiliateDetailsTab = newTab;
        },
        getResidentType(type) {
            let resident_type = ''
            switch (type) {
                case 1:
                    resident_type = this.$t('frontend_affiliate_us_citizen_in_us')
                    break;
                case 2:
                    resident_type = this.$t('frontend_affiliate_us_citizen_in_non_us')
                    break;
                case 3:
                    resident_type = this.$t('frontend_affiliate_non_us_citizen_in_us')
                    break;
                case 4:
                    resident_type = this.$t('frontend_affiliate_non_us_citizen_in_non_us')
                    break;
            }

            return resident_type;
        },
    }



}
</script>

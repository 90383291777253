<template>
  <div>
    <div class="w-full">
      <v-wait for="transactions">
        <div class="vendor-reports bg-white rounded border border-grey-200 mt-4">
          <filters
            :transactions-date-range="transactionsDateRange"
            :filtered-funnels="filteredFunnels"
            :funnels-dropdown="funnelsDropdown"
            :filtered-affiliates="filteredAffiliates"
            @updateDateRange="updateDateRange"
            @updatedFilteredFunnels="filteredFunnels = $event"
            @updatedFilteredAffiliates="filteredAffiliates = $event"
            @clearFilters="clearFilters"
            @reloadData="reloadData"
            @applyFilters="applyFilters"
          />

          <div class="w-full p-4 text-grey-900">
            <div class="flex">
              <div class="w-1/4 mb-6">
                <div class="flex mb-4">
                  <div class="w-full">
                    <gd-tabs
                      :tabs="transactionTabs"
                      :current-tab="transactionsTab"
                      wrapper-class="px-0 gs-default-tabs"
                      tab-class="pb-2 mr-4 gs-default-tabs-item"
                      tab-active-class="border-primary border-b-2 text-primary gs-default-tabs-item-active"
                      line-class="gs-default-tabs-active-line"
                      @onClick="handleTransactionsTabChange"
                    />
                  </div>
                </div>
                <div class="mb-4 flex flex-col">
                  <revenue-view
                    v-if="revenueView"
                    :rev-volume-key="revVolumeKey"
                    :revenue-view-data="revenueViewData"
                  />

                  <default-view
                    v-else
                    :rev-volume-key="revVolumeKey"
                    :default-view-data="defaultViewData"
                  />
                </div>
              </div>

              <chart
                ref="chart"
                :chart-data="chartData"
                :revenue-view="revenueView"
                :series="series"
                :filter-btn="filterBtn"
                @setRevenueView="setRevenueView"
                @updateFilterBtn="updateFilterBtn"
              />
            </div>
          </div>
        </div>
      </v-wait>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import Filters from './report_partials/Filters.vue';
import RevenueView from './report_partials/RevenueView.vue';
import DefaultView from './report_partials/DefaultView.vue';
import Chart from './report_partials/Chart.vue';

export default {
  middleware: 'auth',
  metaInfo() {
    return { title: this.$t('frontend_groovesell_routes_vendor_report') };
  },
  components: {
    AgGridVue,
    Filters,
    RevenueView,
    DefaultView,
    Chart,
  },
  props: {
    hideLoader: {
      default: false,
    }
  },
  data() {
    return {
      revVolumeKey: 1,
      revenueViewData: {
        trialAmount: 0,
        salesAmount: 0,
        rebillsAmount: 0,
        revenueAmount: 0,
        commissionAmount: 0,
        profit: 0,
        refundsAmount: 0,
        netProfit: 0,
        epcEarned: 0,
        processingFee: 0,
      },
      defaultViewData: {
        visitorsCount: 0,
        signupsCount: 0,
        trialCount: 0,
        salesCount: 0,
        rebillsCount: 0,
        refundsCount: 0,
        cancelCount: 0,
      },
      chartData: {
        activeSubscriptionsCount: 0,
        retentionLength: 0,
        projected: 0,
        projectedAfterAff: 0,
        projectedAfterAffRefunds: 0,
      },
      funnelsDropdown: [],
      filteredFunnels: [],
      affiliatesForFilter: [],
      revenueView: true,
      filterBtn: 'revenue',
      transactionsDateRange: {
        startDate: moment().subtract(29, 'days'),
        endDate: moment(),
      },
      gridOptions: null,
      series: [
        {
          name: '',
          data: [],
        },
      ],
      transactionTabs: [
        { title: this.$t('frontend_analytics_vendor_total'), value: 'total' },
        { title: this.$t('frontend_analytics_vendor_sales'), value: 'sales' },
        {
          title: this.$t('frontend_analytics_vendor_rebills'),
          value: 'rebills',
        },
      ],
      transactionsTab: 'total',
      filteredAffiliates: [{ id: 0, name: 'All' }],
    };
  },
  async created() {
    if (this.$route.query.fromDate && this.$route.query.toDate) {
      this.transactionsDateRange = {
        startDate: moment(this.$route.query.fromDate, 'MM/DD/YYYY'),
        endDate: moment(this.$route.query.toDate, 'MM/DD/YYYY'),
      };
    }

    if (this.$route.query.funnels) {
      this.filteredFunnels = JSON.parse(this.$route.query.funnels);
    }

    if (this.$route.query.affFilterType) {
      if (this.$route.query.affFilterType == 1) {
        this.filteredAffiliates = [{ id: 0, name: 'All' }];
      } else if (this.$route.query.affFilterType == 2) {
        this.filteredAffiliates = [{ id: -2, name: 'Only Affiliates' }];
      } else if (this.$route.query.affFilterType == 3) {
        this.filteredAffiliates = [{ id: -1, name: 'Only Non-Affiliates' }];
      } else {
        this.filteredAffiliates = [];

        const selectedAffiliates = JSON.parse(this.$route.query.affiliates);
        for (let i = 0; i < selectedAffiliates.length; i++) {
          this.filteredAffiliates.push({ id: selectedAffiliates[i], name: '' });
        }
      }
    }

    if(this.$route.query.funnel_id) {
      this.filteredFunnels.push('f_' + this.$route.query.funnel_id);
    }

    this.GetChartData();
  },
  methods: {
    updateDateRange(transactionsDateRange) {
      this.transactionsDateRange = transactionsDateRange;
      this.GetChartData();
    },
    updateFilterBtn(filterBtn) {
      this.filterBtn = filterBtn;
      this.GetChartData();
    },
    setRevenueView() {
      this.revVolumeKey++;
      this.revenueView = !this.revenueView;
    },
    clearFilters() {
      this.filteredAffiliates = [{ id: 0, name: 'All' }];
      this.filteredFunnels = [];
      this.GetChartData();
    },
    reloadData() {
      this.GetChartData();
    },
    applyFilters() {
      this.GetChartData();
      this.$root.$emit('modal-close', 'advanced-filters-modal');
    },
    async GetChartData() {
      const { startDate, endDate } = this.transactionsDateRange;

      if (!this.hideLoader) this.$loader.start('GetChartData');

      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const transactionsData = await axios.post('/charts/get-vendor-report', {
        fromDate: moment(startDate).format('MM/DD/YYYY'),
        toDate: moment(endDate).format('MM/DD/YYYY'),
        diffDays,
        include_avg_retention: true,
        chart_value: this.filterBtn,
        selected_tab: this.transactionsTab,
        funnels: this.filteredFunnels,
        affiliates: this.filteredAffiliates,
      });

      this.series[0].data = transactionsData.data.chart_values;
      this.updateSeriesLine();

      const selectedTabData = transactionsData.data.selected_tab_data;
      this.revenueViewData = {
        refundsAmount: selectedTabData.refunded,
        processingFee: selectedTabData.processing_fee,
        revenueAmount: selectedTabData.revenue,
        commissionAmount: selectedTabData.commission,
        trialAmount: selectedTabData.trials,
        salesAmount: selectedTabData.sales,
        rebillsAmount: selectedTabData.rebills,
        profit: selectedTabData.profit,
        netProfit: selectedTabData.net_profit,
        epcEarned: selectedTabData.epc_earned,
      };

      this.defaultViewData = {
        visitorsCount: selectedTabData.visitors_count,
        refundsCount: selectedTabData.refunded_count,
        cancelCount: selectedTabData.cancelled,
        trialCount: selectedTabData.trials_count,
        salesCount: selectedTabData.sales_count,
        rebillsCount: selectedTabData.rebills_count,
        signupsCount: selectedTabData.signups,
      };

      this.chartData = {
        projected: selectedTabData.projected,
        projectedAfterAff: selectedTabData.projected_after_aff,
        projectedAfterAffRefunds: selectedTabData.projected_after_aff_refunds,
        activeSubscriptionsCount: selectedTabData.active_subscriptions,
        retentionLength: selectedTabData.retention_length,
      };

      this.funnelsDropdown = transactionsData.data.funnels;
      if (!this.hideLoader) this.$loader.end('GetChartData');
    },
    updateSeriesLine() {

      this.$nextTick(() => {
        this.$refs.chart.$refs.realtimeChart.updateSeries(
          [
            {
              data: this.series[0].data,
            },
          ],
          false,
          true
        );
      });
    },
    async handleTransactionsTabChange(newTab) {
      this.transactionsTab = newTab;
      this.GetChartData();
    },
  },
};
</script>

<style lang="scss">
.vendor-reports {
  .daterangepicker.show-ranges .drp-calendar.left {
    border: none !important;
  }
  .daterangepicker.show-calendar .ranges {
    border-right: 1px solid #ddd;
  }
  .daterangepicker .ranges li.active {
    background-color: var(--brand-primary);
  }
  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: var(--brand-primary);
  }
  .radio_btns label,
  .radio_btns label input {
    cursor: pointer;
  }

  .chart_section {
    background-color: #191c3c;
  }

  .apexcharts-gridRow,
  .apexcharts-grid-row {
    fill: #fff !important;
  }
  .apexcharts-gridline {
    stroke: #A4A8C0;
  }
  .apexcharts-tooltip-marker,
  .apexcharts-tooltip-title,
  .apexcharts-tooltip.light {
    background: var(--brand-primary-dark) !important;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background: var(--brand-primary-dark) !important;
  }

  .apexcharts-tooltip-marker {
    background: black !important;
  }
  text {
    fill: #191C3C !important;
  }
  stop:first-child {
    stop-color: var(--brand-primary-dark) !important;
  }
  .apexcharts-menu-item,
  .apexcharts-zoom-icon {
    color: var(--brand-primary-dark) !important;
  }
  .apexcharts-bar-area {
    fill: var(--brand-primary-dark) !important;
  }
  .apexcharts-line {
    stroke: var(--brand-primary-dark) !important;
  }
  .apexcharts-gridRow {
    fill: #333;
  }
  .light .apexcharts-selection-icon:not(.selected):hover svg,
  .light .apexcharts-zoom-icon:not(.selected):hover svg,
  .light .apexcharts-zoom-in-icon:hover svg,
  .light .apexcharts-zoom-out-icon:hover svg,
  .light .apexcharts-reset-zoom-icon:hover svg,
  .light .apexcharts-menu-icon:hover svg {
    fill: #fff !important;
  }
  .apexcharts-area-series
    .apexcharts-series-markers
    .apexcharts-marker.no-pointer-events,
  .apexcharts-line-series
    .apexcharts-series-markers
    .apexcharts-marker.no-pointer-events,
  .apexcharts-radar-series path,
  .apexcharts-radar-series polygon {
    pointer-events: none;
    fill: var(--brand-primary-dark);
  }
}
</style>
